<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Experts & Co</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Experts & Co</ion-title>
        </ion-toolbar>
      </ion-header>
    
      <div id="container">
        <form @submit.prevent="login">
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position="stacked">Login</IonLabel>
                <IonInput v-model="form.login"></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position="stacked">Password</IonLabel>
                <IonInput type="password" v-model="form.password"></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton color="primary" expand="block" type="submit">Login</IonButton>
            </IonCol>
          </IonRow>
        </form>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonButton,
  IonCol,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar
} from '@ionic/vue';
import {defineComponent} from 'vue';
import axios from "axios";
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import {detect} from "detect-browser";

const fpPromise = FingerprintJS.load()

export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar, IonCol, IonRow, IonItem, IonLabel, IonInput, IonButton
  },
  data() {
    return {
      form: {
        login: '',
        password: '',
        deviceName: 'unknown',
      }
    }
  },
  setup () {
    console.log('Home setup')
  },
  mounted() {
    console.log('Home mounted')
    const browser = detect();
    const authToken = this.$route.params.token;

    if (this.$route.params.token !== '') {
      this.deviceId().then((device) => {
        axios.get('https://binshare.io/token', {params: {
            'authToken': authToken,
            'deviceName': device,
            'browserType': (browser === null ? 'unknown' : browser.type),
            'browserName': (browser === null ? 'unknown' : browser.name),
            'browserVersion': (browser === null ? 'unknown' : browser.version),
            'browserOs': (browser === null ? 'unknown' : browser.os)
          }
        }).then(response => {
          localStorage.setItem('token', response.data);
          this.$store.dispatch('updateFolders')
          this.$router.push('/folders')
        }).catch((error) => {
          if (error.response.status === 422) {
            alert('Erreur de connection automatique. Veuillez vous connecter manuellement.');
          }
        });
      })
    }
    if(localStorage.getItem('token') !== null) {
      this.$router.push('/folders')
    }
  },
  methods: {
    login() {
      this.deviceId().then((device) => {
        this.form.deviceName = device
        axios.get('https://binshare.io/token', {params: this.form}).then(response => {
          console.log(response)
          localStorage.setItem('token', response.data);
          this.$router.push('/folders')
        }).catch((error) => {
          console.log(error.response)
          if (error.response.status === 422) {
            alert('Erreur de login ou de mot de passe');
          }
        });
      })
    },
    deviceId: async () => {
      // Get the visitor identifier when you need it.
      const fp = await fpPromise
      const result = await fp.get()
      return result.visitorId
    }
  }
});
</script>

<style scoped>
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>