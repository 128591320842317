<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Experts & Co</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Experts & Co</ion-title>
        </ion-toolbar>
      </ion-header>
      <IonRow>
        <IonCol>
          <IonItem v-for="(folder, index) in $store.getters.folders" :key="index" :router-link="'/folders/' + folder.id">
            <IonLabel>
              <h2>{{folder.name}}</h2>
              <h3>{{folder.filesCount}} fichier(s)</h3>
            </IonLabel>
          </IonItem>
        </IonCol>
      </IonRow>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCol, IonRow, IonItem, IonLabel } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: "Folders",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar, IonCol, IonRow, IonItem, IonLabel
  },
  data() {
    return {
    }
  },
  mounted() {
    console.log('Folder mounted')
    this.$store.dispatch('updateFolders')
  },
  setup() {
    console.log('Folder setup')
  }

})
</script>

<style scoped>

</style>