<template>
  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton></IonBackButton>
        </IonButtons>
        <ion-title> {{$store.getters.folder.name}}</ion-title>
      </IonToolbar>
    </IonHeader>

    <IonContent :fullscreen="true">
      <IonRow>
        <IonCol>
          <IonCard>
            <IonCardContent>
              Voici la liste de vos fichiers. Vous pouvez aussi déposer des fichiers dans ce dossier en les envoyant à l'adresse suivante: <br>
              <a :href="'mailto:' + $store.getters.folder.email"><IonIcon :icon="icon.mail"></IonIcon> {{$store.getters.folder.email}}</a>
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonItem v-for="(file, index) in $store.getters.folder.files" :key="index">
            <IonLabel>
              <h2>{{file.filename}}</h2>
              <h3>{{file.size / 1000}} Kb</h3>
            </IonLabel>
          </IonItem>
        </IonCol>
      </IonRow>
      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <IonFabButton :router-link="'/folders/' + $route.params.id + '/photo'">
          <ion-icon :icon="icon.add"></ion-icon>
        </IonFabButton>
      </IonFab>
    </IonContent>
  </IonPage>
</template>

<script>
import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCol, IonRow, IonItem, IonLabel, IonIcon, IonFab, IonFabButton, IonButtons, IonBackButton, IonCard, IonCardContent } from '@ionic/vue';
import {
  add, mail
} from 'ionicons/icons';
import { defineComponent } from 'vue';
export default defineComponent({
  name: "Files",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar, IonCol, IonRow, IonItem, IonLabel,
    IonIcon,
    IonFab, IonFabButton, IonButtons, IonBackButton, IonCard, IonCardContent
  },
  data() {
    return {
      icon: {add, mail}
    }
  },
  mounted() {
    console.log('Files mounted')
    if (this.$store.getters.foldersLoaded !== true) {
      this.$store.dispatch('updateFolders')
    }
    this.$store.getters.folders.forEach((folder, index) => {
      if (String(folder.id) === this.$route.params.id) {
        console.log('New index found:', index)
        this.$store.commit('SET_CURRENT_FOLDER', index)
        this.$store.dispatch('updateFolders')
      }
    })
  },
  setup() {
    console.log('Files setup')
  },
})
</script>

<style scoped>

</style>