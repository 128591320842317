<template>
  <IonPage>
    <IonContent :fullscreen="true">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton></IonBackButton>
          </IonButtons>
          <ion-title >{{ $store.getters.folder.name }}</ion-title>
        </IonToolbar>
      </IonHeader>
      <IonRow v-if="$store.getters.folder.pendingFiles.length === 0">
        <IonCol>
          <ion-card>
            <ion-card-content>
              Cliquez sur "Scanner un document" pour ajouter un document.
            </ion-card-content>
          </ion-card>
        </IonCol>
      </IonRow>
      <IonRow v-if="$store.getters.folder.pendingFiles.length > 0">
        <IonCol>
          <ion-card>
            <ion-card-content>
              Vos fichiers en cours d'envois...
            </ion-card-content>
          </ion-card>

          <IonItem>
            <IonLabel>Upload...</IonLabel>
            <ion-progress-bar :value="$store.getters.progress"></ion-progress-bar>
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonButton :disabled="$store.getters.folder.pendingFiles.length > 0" expand="block" style="bottom: 0" @click.prevent="takePhoto">
            <IonIcon :icon="icon.scan" :router-link="'folders/' + $route.params.id + '/photo'"></IonIcon>&nbsp;<strong>Scanner un document</strong>
          </IonButton>
        </IonCol>
      </IonRow>

    </IonContent>

  </IonPage>
</template>

<script>
import {
    IonContent,
    IonHeader, IonPage, IonButton, IonIcon,
    IonTitle,
    IonToolbar, IonCol, IonRow, IonItem, IonLabel, IonCard, IonCardContent, IonBackButton, IonButtons} from '@ionic/vue';
import { defineComponent } from 'vue';
import { Camera, CameraResultType } from '@capacitor/camera';
import {
  scan
} from 'ionicons/icons';
export default defineComponent({
  name: "Photo",
  components: {
    IonContent,
    IonHeader, IonPage, IonButton, IonIcon,
    IonTitle,
    IonToolbar, IonCol, IonRow, IonItem, IonLabel, IonCard , IonCardContent, IonBackButton, IonButtons
  },
  data() {
    return {
      icon: {scan},
    }
  },
  setup() {
    console.log('Setup photo')
  },
  methods: {
    async takePhoto() {
      const picture = await Camera.getPhoto({
        resultType: CameraResultType.Uri,
        quality: 90,
        allowEditing: true,
      });
      this.$store.dispatch('addFileToSend', {webPath: picture.webPath, progress: 0.0}).then(async () => {
        this.$store.dispatch('sendFile', picture)
      })
    }
  }
})
</script>

<style scoped>

</style>