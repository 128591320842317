import { InjectionKey } from 'vue'
import { createStore, Store } from 'vuex'
import axios from "axios";
import {toastController} from "@ionic/vue";
import router from './router';

// define your typings for the store state
export interface State {
    folders: [{
        id: null;
        name: null;
        filesCount: 0;
        files: [
            {
                filename: null;
                id: null;
                size: null;
            }
        ];
        pendingFiles: [{
            webPath: '';
            progress: 0.0;
        }];
    }];
    folderLoaded: boolean;
    currentFolderIndex: 0;
    progress: 0.0;
}

// define injection key
export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
    mutations: {
        UPDATE_FOLDERS(state, folders) {
            state.folders = folders
             state.folderLoaded = true
        },
        ADD_PENDING_FILE(state, data) {
            state.folders[state.currentFolderIndex].pendingFiles.push(data.file)
        },
        INIT_APP(state) {
            console.log('Init app...')
            state.currentFolderIndex = 0
            state.folders = [{
                id: null,
                name: null,
                filesCount: 0,
                files: [
                    {
                        filename: null,
                        id: null,
                        size: null,
                    }
                ],
                pendingFiles: [{
                    webPath: '',
                    progress: 0.0
                }]
            }]
            state.folderLoaded = false;
        },
        ADD_FILE_TO_FOLDER(state, file) {
            state.folders[state.currentFolderIndex].files.push(file)
            state.folders[state.currentFolderIndex].filesCount += 1
        },
        SET_CURRENT_FOLDER(state, index) {
            state.currentFolderIndex = index
        },
        SET_FOLDER_AS_LOADED(state) {
            state.folderLoaded = true
        },
        SET_FOLDER_AS_NOT_LOADED(state) {
            state.folderLoaded = false
        },
        UPDATE_PROGRESS(state, progress) {
            state.progress = progress
        }
    },
    getters: {
        folders(state) {
            return state.folders
        },
        folder(state) {
            if (typeof state.folders[state.currentFolderIndex] === "undefined"){
                return []
            }
            return state.folders[state.currentFolderIndex]
        },
        foldersLoaded(state) {
            return state.folderLoaded
        },
        currentFolderIndex(state) {
            return state.currentFolderIndex
        },
        progress(state) {
            return state.progress
        }
    },
    actions: {
        INIT_APP (context) {
            context.commit('INIT_APP');
        },
        updateFolders (context) {
            axios.get('https://binshare.io/api/folders', {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                }
            }).then((response) => {
                context.commit('UPDATE_FOLDERS', response.data)
            }).catch(async (error) => {
                if (error.response.status === 401) {
                    await router.push('/')
                    const toast = await toastController
                        .create({
                            message: 'Vous êtes déconnecté. Veuillez vous connecter.',
                            duration: 2000
                        })
                    return toast.present()
                }
            });
        },
        async addFileToSend (context, picture) {
            context.commit('ADD_PENDING_FILE', {
                webPath: picture.webPath,
                progress: 0.0
            })
        },
        async sendFile (context, picture) {
            const base64Data = picture.webPath;
            const base64 = await fetch(base64Data);
            const blob = await base64.blob();
            const formData = new FormData();
            formData.append("file", blob);
            axios.get('https://binshare.io/api/folders/' + context.state.folders[context.state.currentFolderIndex].id + '/store_token',{
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).then((response) =>{
                formData.append("from_app", response.data.store_token);
                axios.post(
                    'https://binshare.io/api/folders/' + context.state.folders[context.state.currentFolderIndex].id + '/files/app',
                    formData,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('token'),
                            'Content-Type': `multipart/form-data`
                        },
                        onUploadProgress: function(progressEvent: ProgressEvent) {
                            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                            context.commit('UPDATE_PROGRESS', percentCompleted/100)
                            console.log(percentCompleted)
                        }
                    }
                ).then(async (response) => {
                    const toast = await toastController
                        .create({
                            message: 'Votre scan à été envoyé.',
                            duration: 2000
                        })
                    context.state.folders[context.state.currentFolderIndex].pendingFiles.splice(0, 1)
                    context.commit('ADD_FILE_TO_FOLDER', response.data);
                    context.commit('UPDATE_PROGRESS', 0.0)
                    return toast.present();
                }).catch((error) => {
                    console.log(error)
                });
            })
        }
    }
})