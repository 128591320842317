import { createRouter, createWebHashHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue'
import Folders from '../views/Folders.vue'
import Files from '../views/Files.vue'
import Photo from '../views/Photo.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home/:token?',
    name: 'Home',
    component: Home
  },
  {
    path: '/folders',
    name: 'Folders',
    component: Folders
  },
  {
    path: '/folders/:id',
    name: 'Files',
    component: Files
  },
  {
    path: '/folders/:id/photo',
    name: 'Photo',
    component: Photo
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
